/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

import "../styles/index.sass";

const TemplateWrapper = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            logo {
              alt,
              url
            }
            copyright
          }
          allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                profileType
                url
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <div className="bodyWrapper grey-lines">
            <HelmetDatoCms
              favicon={data.datoCmsSite.faviconMetaTags}
              seo={data.datoCmsHome.seoMetaTags}
            />

            <div className={`container ${showMenu ? "is-open" : ""}`}>
              <div className="container__sidebar">
                <div className="sidebar">
                  <h6 className="sidebar__title">
                    <Link to="/"><img src={data.datoCmsHome.logo.url} alt={data.datoCmsHome.logo.alt} /></Link>
                  </h6>
                  <ul className="sidebar__menu">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                  </ul>
                  <p className="sidebar__social">
                    {data.allDatoCmsSocialProfile.edges.map(({ node: profile }) => (
                      <a
                      key={profile.profileType}
                      href={profile.url}
                      target="blank"
                      className={`social social--${profile.profileType.toLowerCase()}`}
                      >
                        {" "}
                      </a>
                    ))}
                  </p>
                  <div className="sidebar__copyright">
                    {data.datoCmsHome.copyright}
                  </div>
                </div>
              </div>
            </div>

            <div className="container__body">
              <div className="container__mobile-header">
                <div className="mobile-header">
                  <div className="mobile-header__menu">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setShowMenu(!showMenu);
                      }}
                    />
                  </div>
                  <div className="mobile-header__logo">
                    <Link to="/"><img src={data.datoCmsHome.logo.url} alt={data.datoCmsHome.logo.alt} /></Link>
                  </div>
                </div>
              </div>
              {children}
            </div>
          </div>

          <footer role="contentinfo">
            <div className="copyright">
              {data.datoCmsHome.copyright}

              <a href="mailto:info@flatheadesl.com">info@flatheadesl.com</a>
            </div>
          </footer>
        </>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object
};

export default TemplateWrapper;
/* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/
